import { Node, mergeAttributes } from '@tiptap/core'
import { ReactNodeViewRenderer } from '@tiptap/react'
import HoverMenuNodeView from './HoverMenuNodeView'

export const HoverMenuNode = Node.create({
	name: 'hoverMenu',

	group: 'inline',
	inline: true,
	content: 'text*',
	priority: 900,

	addAttributes() {
		return {
			// use a camelCase key in Tiptap (e.g. refId)
			refId: {
				default: null,
				parseHTML: (element) => {
					// Read from the DOM’s data-ref-id
					return element.getAttribute('data-ref-id') || null
				},
				renderHTML: (attributes) => {
					// Render out to data-ref-id if present
					if (!attributes.refId) return {}
					return { 'data-ref-id': attributes.refId }
				},
			},

			// you can also do the same for data-hover-menu if you want
			hoverMenu: {
				default: 'true',
				parseHTML: (element) => element.getAttribute('data-hover-menu') || 'true',
				renderHTML: (attributes) => {
					return { 'data-hover-menu': attributes.hoverMenu }
				},
			},
		}
	},

	parseHTML() {
		return [
			{
				tag: 'span[data-hover-menu]',
				getAttrs: (dom) => {
					// If it’s not meant to match, return false.
					if (
						dom.hasAttribute('data-hover-menu') &&
						!dom.hasAttribute('data-edited') &&
						!dom.hasAttribute('data-focus')
					) {
						return {
							hoverMenu: dom.getAttribute('data-hover-menu') ?? 'true',
							refId: dom.getAttribute('data-ref-id') ?? null,
						}
					}
					return false
				},
			},
		]
	},

	renderHTML({ HTMLAttributes }) {
		return [
			'span',
			mergeAttributes(HTMLAttributes, {
				// Force data-hover-menu to "true" if needed
				'data-hover-menu': HTMLAttributes.hoverMenu || 'true',
				// If refId is present, map it onto data-ref-id
				...(HTMLAttributes.refId ? { 'data-ref-id': HTMLAttributes.refId } : {}),
			}),
			0,
		]
	},

	addNodeView() {
		return ReactNodeViewRenderer(HoverMenuNodeView)
	},
})
