import { useSelector } from 'react-redux'
import { useAuth } from '../contexts/Auth'
import { selectPaddle, selectUser } from '../redux/systemSlice'
import { FEATURE_FLAGS, usePricing } from './featureFlags'
import { useFeatureFlagVariantKey, usePostHog } from 'posthog-js/react'
import { useState } from 'react'

const useCheckout = () => {
	const [loading, setLoading] = useState(false)
	const usePaddle = useFeatureFlagVariantKey(FEATURE_FLAGS.PADDLE)
	const paddle = useSelector(selectPaddle)
	const { currentUser } = useAuth() as any
	const user = useSelector(selectUser)
	const pricing = usePricing()
	const posthog = usePostHog()

	const checkout = async (annual: boolean) => {
		setLoading(true)
		if (!user) return
		posthog.capture('checkout-started', { annual })
		if (usePaddle === 'test' && paddle) {
			paddle.Checkout.open({
				items: [
					{
						priceId: annual ? 'pri_01jfdrvvs5tcs8xr5xfdwda4wf' : 'pri_01jfdrdj0y2p0t588srvkme9pb',
						quantity: 1,
					},
				],
				customData: {
					user_id: user.id,
				},
				settings: {
					successUrl: window.location.href + `/?showModal=true${annual ? '&annual=true' : ''}`,
					variant: 'one-page',
				},
			})
			setLoading(false)
		} else {
			const requestOptions = {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					authorization: currentUser ? `Bearer ${await currentUser.getIdToken()}` : '',
				},
				body: JSON.stringify({
					uid: currentUser.uid,
					humanizerUpgrade: false,
					price: annual ? pricing.stripePriceId.yearly ?? pricing.stripePriceId.monthly : pricing.stripePriceId.monthly,
					annual: annual,
					returnUrl: window.location.href,
				}),
			}
			fetch(process['env']['REACT_APP_API_ROOT'] + '/payments/checkout/', requestOptions)
				.then((res) => res.json())
				.then((response: { url: string }) => {
					if (response.url) {
						setLoading(false)
						window.open(response.url, '_self')
					}
				})
				.catch((err) => {
					setLoading(false)
					console.error(err)
				})
		}
	}

	return { checkout, loading }
}

export default useCheckout
