import './_Error.scss'
import { useEffect } from 'react'
import { contactFormLink } from '../../App'
import Button from '../Buttons'
import Modal from './Modal'
import { usePostHog } from 'posthog-js/react'

function ErrorModal({ open, closeModal }: { open: boolean; closeModal: () => void }) {
	const posthog = usePostHog()
	useEffect(() => {
		if (open) {
			posthog.capture('error-modal-triggered')
		}
	}, [open])

	return (
		<Modal open={open} closeModal={closeModal} portalClassName="error">
			<h5 className="highlighted header-md">There's been an error.</h5>
			<p>Sorry, an unexpected error has occurred. Please try again later or contact support.</p>
			<Button
				type="secondary"
				onClick={() => {
					window.open(contactFormLink, '_blank')
				}}
			>
				<span>Contact support</span>
			</Button>
		</Modal>
	)
}

export default ErrorModal
