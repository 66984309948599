import { useEffect, useMemo, useState } from 'react'
import { useAtom } from 'jotai'
import { Tooltip } from '@mui/material'
import { useFeatureFlagVariantKey, usePostHog } from 'posthog-js/react'
import { useWordCount } from './hooks'
import { DetectionResponse, editorAtom, MAX_DETECTOR_WORD_COUNT } from './constants'
import useLoadingDots from '../../hooks/loadingDots'
import Button from '../../Components/Buttons'
import Modal from '../../Components/Modals/Modal'
import { LuTriangleAlert, LuFlag, LuInfo, LuLoader, LuCircleX } from 'react-icons/lu'
import { Bounce, toast } from 'react-toastify'
import { useSelector } from 'react-redux'
import { selectUser } from '../../redux/systemSlice'
import { DocumentState } from '../../redux/types'
import { selectDocumentState } from '../../redux/docSlice'
import { useDocId } from '../../hooks/docID'
import { CONSTANTS } from '../../constants'
import { FEATURE_FLAGS } from '../../hooks/featureFlags'
import { FaRegCheckCircle } from 'react-icons/fa'

const zeroAIScore = {
	ai: 0,
	mixed: 0,
	human: 1,
}

const CheckForAi = ({
	text,
	label = 'Check for AI',
	buttonChildren,
	emphasizeBtn = false,
	humanized = false,
	onHumanizeClick,
	context = 'humanizer',
}: {
	text: string
	label?: string
	buttonChildren?: React.ReactNode
	emphasizeBtn?: boolean
	humanized?: boolean
	onHumanizeClick: () => void
	context?: 'humanizer' | 'detector' | 'writer'
}) => {
	const [detectionModalOpen, setDetectionModalOpen] = useState(false)
	const [thirdPartyScoresLoading, setThirdPartyScoresLoading] = useState(false)
	const [editorState, setEditorState] = useAtom(editorAtom)
	const wordCount = useWordCount(text)
	const loadingDots = useLoadingDots(editorState.aiDetectionScoreLoading)
	const posthog = usePostHog()
	const user = useSelector(selectUser)
	const docId = useDocId()
	const documentState: DocumentState = useSelector((state) => selectDocumentState(state, docId))
	const humanizerV2FF = useFeatureFlagVariantKey(FEATURE_FLAGS.HUMANIZER_V4)
	const sentenceHumanizerFF = useFeatureFlagVariantKey(FEATURE_FLAGS.SENTENCE_HUMANIZER)
	const humanizedAIScoreFF = useFeatureFlagVariantKey(FEATURE_FLAGS.HUMANIZED_AI_SCORE)

	const NoAI = <FaRegCheckCircle size={18} className="text-success-default" />
	const LikelyAI = <LuTriangleAlert size={18} className="text-warning-dark" />
	const AI = <LuCircleX size={18} className="text-danger-default" />
	const _humanized =
		humanized ||
		editorState.textInputValue.trim() === editorState.humanizedText?.trim() ||
		(humanizedAIScoreFF === 'test' && context === 'writer' && documentState.humanized)

	const detectors =
		humanizerV2FF === 'test' || sentenceHumanizerFF === 'test'
			? [
					{ name: 'turnitin', displayName: 'Turnitin', score: zeroAIScore },
					{ name: 'gptzero', displayName: 'GPTZero', score: zeroAIScore },
					{ name: 'copyleaks', displayName: 'Copyleaks', score: zeroAIScore },
					{ name: 'sapling', displayName: 'Sapling', score: zeroAIScore },
					{ name: 'contentatscale', displayName: 'ContentAtScale', score: zeroAIScore },
					{ name: 'zerogpt', displayName: 'ZeroGPT', score: zeroAIScore },
			  ]
			: [
					{ name: 'scoreGptZero', displayName: 'Turnitin', score: zeroAIScore },
					{ name: 'scoreGptZero', displayName: 'GPTZero', score: zeroAIScore },
					{ name: 'scoreCopyLeaks', displayName: 'Copyleaks', score: zeroAIScore },
					{ name: 'scoreSapling', displayName: 'Sapling', score: zeroAIScore },
					{ name: 'scoreContentAtScale', displayName: 'ContentAtScale', score: zeroAIScore },
					{ name: 'scoreZeroGPT', displayName: 'ZeroGPT', score: zeroAIScore },
			  ]

	const checkForAI = async () => {
		setEditorState((editorState) => ({ ...editorState, aiDetectionScoreLoading: true }))

		const requestOptions = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				userId: user.id,
				text: text,
				individualScores: true,
			}),
		}
		fetch(
			process['env']['REACT_APP_API_ROOT'] +
				(humanizerV2FF === 'test' || sentenceHumanizerFF === 'test'
					? '/essay/secondary-client-detect/'
					: '/essay/detect/'),
			requestOptions
		)
			.then((res) => res.json())
			.then((response: DetectionResponse) => {
				if (context === 'writer') {
					posthog.capture('detect-essay', {
						score:
							humanizerV2FF === 'test' || sentenceHumanizerFF === 'test'
								? response.gptzero.ai
								: // @ts-ignore
								  response.scoreGptZero.ai,
						humanized: documentState.humanized,
					})
				} else {
					posthog.capture('detect', {
						num_words: wordCount,
						context,
						score:
							humanizerV2FF === 'test' || sentenceHumanizerFF === 'test'
								? response.gptzero.ai
								: // @ts-ignore
								  response.scoreGptZero.ai,
					})
				}
				setThirdPartyScoresLoading(true)
				const timer = setTimeout(() => setThirdPartyScoresLoading(false), 2000)
				setEditorState({
					...editorState,
					aiDetectionScore: response,
					aiDetectionScoreText: text,
					showDetectionBox: true,
					aiDetectionScoreLoading: false,
				})
				return () => {
					clearTimeout(timer)
				}
			})
	}

	const scores = useMemo(() => {
		if (_humanized) {
			return detectors
		}
		return detectors.map((detector) => {
			if (!editorState.aiDetectionScore) {
				return {
					name: detector.name,
					displayName: detector.displayName,
					score: zeroAIScore,
				}
			}
			if (detector.name in editorState.aiDetectionScore) {
				return {
					name: detector.name,
					displayName: detector.displayName,
					// @ts-ignore
					score: editorState.aiDetectionScore[detector.name],
				}
			}
			return {
				name: detector.name,
				displayName: detector.displayName,
				score: zeroAIScore,
			}
		})
	}, [editorState.aiDetectionScore, _humanized])

	const isAI = useMemo(() => {
		if (_humanized) {
			return false
		}
		const averageScore = scores.reduce((acc, detector) => acc + (detector.score?.ai ?? 0), 0) / scores.length
		return averageScore >= 0.5
	}, [editorState.aiDetectionScore, _humanized])

	useEffect(() => {
		if (detectionModalOpen && !_humanized) {
			checkForAI()
		}
		if (_humanized && detectionModalOpen) {
			setEditorState((editorState) => ({ ...editorState, aiDetectionScoreLoading: true }))
			setThirdPartyScoresLoading(true)
			const timer = setTimeout(
				() => setEditorState((editorState) => ({ ...editorState, aiDetectionScoreLoading: false })),
				2000
			)
			const secondTimer = setTimeout(() => setThirdPartyScoresLoading(false), 4000)
			return () => {
				clearTimeout(timer)
				clearTimeout(secondTimer)
			}
		}
	}, [detectionModalOpen, _humanized])

	const checkForAIBtn = (
		<Button
			type={emphasizeBtn ? 'default' : 'outline'}
			onClick={() => {
				if (wordCount < 20) {
					toast.error(context === 'writer' ? 'Please type at least 20 words.' : 'Please enter at least 20 words.', {
						position: 'top-center',
						autoClose: 3000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: 'light',
						transition: Bounce,
					})
					return
				}
				setDetectionModalOpen(true)
			}}
			disabled={
				context === 'writer'
					? documentState &&
					  (documentState.isHumanizing ||
							documentState.isGenerating ||
							documentState.isTypingAddMore ||
							documentState.isTypingEssay ||
							documentState.content === '' ||
							documentState.content === CONSTANTS.EMPTY_DOCUMENT ||
							(editorState.aiDetectionScoreLoading && detectionModalOpen))
					: wordCount === 0 ||
					  editorState.isLoadingHumanizeText ||
					  wordCount > MAX_DETECTOR_WORD_COUNT ||
					  (editorState.aiDetectionScoreLoading && detectionModalOpen)
			}
			className={`px-0 ${emphasizeBtn ? 'text-white' : ''} ${context === 'writer' ? '!py-1 !font-normal' : ''}`}
		>
			{buttonChildren ? (
				buttonChildren
			) : (
				<div className="flex items-center gap-2 px-3">
					<LuFlag /> {label}
				</div>
			)}
		</Button>
	)

	return (
		<>
			<Modal open={detectionModalOpen} closeModal={() => setDetectionModalOpen(false)}>
				{editorState.aiDetectionScoreLoading ? (
					<div className="flex items-center justify-center h-72 text-2xl font-semibold max-w-3xl md:w-[48rem] w-64">
						Checking for AI{loadingDots}
					</div>
				) : (
					<>
						<div className="flex flex-col gap-4 max-w-3xl overflow-y-auto md:p-8 p-4">
							<div className="flex flex-wrap items-center gap-3 justify-between pr-5">
								<div className="flex flex-wrap items-center gap-3">
									<div
										className={`${
											isAI ? 'border-danger-default' : 'border-success-default text-xs'
										} flex h-14 w-14 items-center justify-center rounded-full border-4 bg-card font-semibold`}
									>
										{isAI ? 'AI' : 'Human'}
									</div>
									<div className="font-semibold text-lg">
										Your text is likely to be written{' '}
										{isAI ? (
											<>
												by an <span className="text-danger-default">AI</span>
											</>
										) : (
											<>
												by a <span className="text-success-default">human</span>
											</>
										)}
										.
									</div>
								</div>
								{isAI && (
									<Button
										type="default"
										className="mr-3"
										onClick={() => {
											setDetectionModalOpen(false)
											onHumanizeClick()
										}}
									>
										Humanize
									</Button>
								)}
							</div>
							<p className="text-center text-sm text-gray-700">
								<LuInfo className="inline" size={16} /> Given the rapid evolution of AI-generated content, these results
								should not be used to penalize students.
							</p>
							<div className="rounded border p-4 dark:text-slate-200 max-h-72 overflow-y-auto">{text}</div>
							<div className="flex flex-wrap justify-between">
								<Tooltip
									title="Good AI utilizes AI technology to assess the likelihood that major AI detectors will identify a piece of text as AI-generated. The models are trained on text similar to that used by leading detection platforms, ensuring our predictions align closely with their outcomes."
									placement="top-start"
								>
									<div>
										<div className="text-lg font-semibold dark:text-slate-200">
											Third-Party AI Scores <LuInfo className="inline-block ml-1" />
										</div>
									</div>
								</Tooltip>
								<div className="flex gap-4">
									<div className="flex items-center gap-1 font-semibold text-success-default">
										{NoAI}
										Human
									</div>
									<div className="flex items-center gap-1 font-semibold text-warning-dark">
										{LikelyAI}
										Likely AI
									</div>
									<div className="flex items-center gap-1 font-semibold text-danger-default">
										{AI}
										AI
									</div>
								</div>
							</div>
							<div className="md:flex grid grid-cols-2 justify-between gap-2 overflow-x-scroll rounded border bg-card p-3">
								{scores.map((detector) => {
									let icon = null
									if (thirdPartyScoresLoading) {
										icon = <LuLoader className="h-5 w-5 animate-spin duration-500" />
									} else if (detector.score.ai > 0.5) {
										icon = AI
									} else {
										icon = NoAI
									}
									return (
										<>
											<div className="flex items-center gap-2">
												{icon}
												<div className="max-w-[5rem] md:max-w-none truncate md:overflow-visible md:whitespace-normal">
													{detector.displayName}
												</div>
											</div>
										</>
									)
								})}
							</div>
						</div>
					</>
				)}
			</Modal>
			{checkForAIBtn}
		</>
	)
}

export default CheckForAi
