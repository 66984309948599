export const humanizedTextResultToDrafts = (
	result: {
		lines: { originalLine: string; sentences: { variations: string[] }[] }[]
	},
	htmlFormat?: boolean
): string[] => {
	let humanizedDrafts = ['', '', '']

	result.lines?.forEach((line: { originalLine: string; sentences: { variations: string[] }[] }) => {
		if (line.originalLine !== '' || !htmlFormat) {
			if (htmlFormat) {
				humanizedDrafts[0] += '<p>'
				humanizedDrafts[1] += '<p>'
				humanizedDrafts[2] += '<p>'
			}

			line.sentences.forEach((sentence) => {
				humanizedDrafts[0] += sentence.variations[0]?.trim() + ' '
				humanizedDrafts[1] += sentence.variations[1]?.trim() + ' '
				humanizedDrafts[2] += sentence.variations[2]?.trim() + ' '
			})
			if (htmlFormat) {
				humanizedDrafts[0] += '</p>'
			} else {
				humanizedDrafts[0] += '\n'
				humanizedDrafts[1] += '\n'
				humanizedDrafts[2] += '\n'
			}
		}
	})

	return humanizedDrafts
}
