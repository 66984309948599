import { TextareaAutosize } from '@mui/material'
import { Command, Editor } from '@tiptap/core'
import React, { useRef, useState } from 'react'
import Button from '../Buttons'
import { useCookieUser } from '../../hooks/cookieUser'
import { useSelector } from 'react-redux'
import { selectUser } from '../../redux/systemSlice'
import { DOMParser } from 'prosemirror-model'
import { useDocId } from '../../hooks/docID'
import useLoadingDots from '../../hooks/loadingDots'
import { usePostHog } from 'posthog-js/react'
import { useAtom } from 'jotai'
import { accountModalAtom } from '../../Pages/Create/Create'
import useIsPremium from '../../hooks/useIsPremium'
import { DocumentState } from '../../redux/types'
import { selectDocumentState } from '../../redux/docSlice'
import { htmlToString } from '../../helpers/utility'

export const AddMenu = React.forwardRef(
	(
		props: {
			editor: Editor
			command?: (command: Command) => void
			/** When true, append the generated text at the end of the document (with a new line). */
			appendAtEnd?: boolean
		},
		ref
	) => {
		const { editor } = props
		const docId = useDocId()
		const [, setAccountModalState] = useAtom(accountModalAtom)
		const [prompt, setPrompt] = useState('')
		const [generating, setGenerating] = useState(false)
		const [contentToAdd, setContentToAdd] = useState<string | undefined>(undefined)
		const [selectedLength, setSelectedLength] = useState<'SHORT' | 'MEDIUM' | 'LONG'>('MEDIUM')
		const documentState: DocumentState | undefined = useSelector((state) => selectDocumentState(state, docId))
		const scrollContainer = useRef<HTMLDivElement>(null)
		const user = useSelector(selectUser)
		const { userIDCookie } = useCookieUser()
		const loadingDots = useLoadingDots(generating)
		const isPremium = useIsPremium()
		const posthog = usePostHog()

		const generateText = () => {
			posthog.capture('insert-more-generated')
			if (!isPremium) {
				setAccountModalState((state) => ({ ...state, subscriptionModalOpen: true }))
				editor.commands.setTextSelection(editor.state.selection.from - 10)
				return
			}
			const contentBefore = getContentBeforeAndAfter()
			setGenerating(true)

			const requestOptions = {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					prompt: prompt,
					topic: htmlToString(documentState?.title ?? ''),
					userId: user.id ?? userIDCookie,
					length: selectedLength,
					textBeforeInsert: contentBefore,
					docId: docId,
				}),
			}
			fetch(process['env']['REACT_APP_API_ROOT'] + '/essay/insert-more/', requestOptions)
				.then((res) => res.json())
				.then((response: { content: string }) => {
					if (response.content) {
						setContentToAdd(response.content)
						setGenerating(false)
					}
				})
		}

		const getContentBeforeAndAfter = () => {
			if (props.appendAtEnd) {
				const entireDoc = editor.getText()
				return entireDoc
					.split(' ')
					.slice(-500) // keep last 500 words
					.join(' ')
			}

			const { from } = editor.state.selection
			const textUpToCursor = editor.state.doc.textBetween(0, from, '\n', '\n')
			const slashPosInText = textUpToCursor.lastIndexOf('/')

			if (slashPosInText === -1) {
				return {
					contentBefore: editor.getText(),
					contentAfter: '',
				}
			}
			const contentBefore = textUpToCursor.slice(0, slashPosInText)

			// Return only the last 500 words of contentBefore
			return contentBefore.split(' ').slice(-500).join(' ')
		}

		return (
			<div
				ref={scrollContainer}
				className="shadow bg-white rounded p-2 border border-gray-400 sm:w-[500px] w-[calc(100vw-3rem)]"
			>
				{contentToAdd ? (
					<div className="flex flex-col gap-1">
						<div
							className="max-h-52 overflow-y-auto"
							dangerouslySetInnerHTML={{
								__html: contentToAdd
									.replaceAll('h6', 'h6 style="font-size: 22px"')
									.replaceAll('<p>', '<div>')
									.replaceAll('</p>', '</div>'),
							}}
						/>
						<div className="flex justify-end gap-2 mt-3">
							<Button
								type="outline"
								onClick={() => {
									setContentToAdd(undefined)
								}}
							>
								Try again
							</Button>
							<Button
								type="default"
								onClick={() => {
									posthog.capture('insert-more-inserted')

									const { state, dispatch } = editor.view
									const tr = state.tr
									const { from } = state.selection

									// If we're not appending at the end, we remove the slash if it exists and insert at cursor
									if (!props.appendAtEnd) {
										const docText = state.doc.textBetween(0, from, '\n', '\n')
										const slashIndexInText = docText.lastIndexOf('/')

										if (slashIndexInText !== -1) {
											const slashFrom = from - (docText.length - slashIndexInText)
											const slashTo = slashFrom + 1
											tr.delete(slashFrom, slashTo)
										}
									}

									const parser = DOMParser.fromSchema(editor.schema)
									const dom = document.createElement('div')
									dom.innerHTML = contentToAdd
									const fragment = parser.parse(dom)

									if (fragment) {
										if (props.appendAtEnd) {
											// Append at the end, with a newline first
											const endPos = state.doc.content.size
											// Insert a newline
											tr.insert(endPos, state.schema.text('\n'))
											// Then insert the generated content
											tr.insert(endPos + 1, fragment.content)
										} else {
											// Insert at the cursor
											tr.insert(state.selection.from, fragment.content)
										}
									}

									if (props.appendAtEnd) {
										window.scrollTo(0, document.body.scrollHeight)
									}

									dispatch(tr)
									setContentToAdd(undefined)
								}}
							>
								Insert Text
							</Button>
						</div>
					</div>
				) : (
					<div className={`flex flex-col gap-1 ${generating ? 'pointer-events-none opacity-50' : ''}`}>
						<div className="text-sm font-semibold text-gray-700">
							What do you want to write about? Leave blank to continue writing.
						</div>
						<TextareaAutosize
							className="border border-gray-400 rounded p-2 resize-none outline-none"
							placeholder='Ex: "Write about the importance of recycling"'
							minRows={3}
							maxRows={6}
							value={prompt}
							onChange={(e) => setPrompt(e.target.value)}
						/>
						<div className="text-sm font-semibold text-gray-700 mt-3">How much text do you need?</div>
						<div className="flex justify-between">
							<div className="flex gap-2 flex-wrap">
								<Button
									type="outline"
									onClick={() => {
										setSelectedLength('SHORT')
									}}
									className="text-sm font-normal pt-1 pb-1"
									emphasize={selectedLength === 'SHORT'}
								>
									A few sentences
								</Button>
								<Button
									type="outline"
									onClick={() => {
										setSelectedLength('MEDIUM')
									}}
									className="text-sm font-normal pt-1 pb-1"
									emphasize={selectedLength === 'MEDIUM'}
								>
									One paragraph
								</Button>
								<Button
									type="outline"
									onClick={() => {
										setSelectedLength('LONG')
									}}
									className="text-sm font-normal pt-1 pb-1"
									emphasize={selectedLength === 'LONG'}
								>
									Multiple paragraphs
								</Button>
							</div>
						</div>
						<Button type="default" onClick={generateText} className="mt-4">
							{generating ? 'Generating' + loadingDots : 'Generate'}
						</Button>
					</div>
				)}
			</div>
		)
	}
)

AddMenu.displayName = 'MenuList'

export default AddMenu
